import { type JsonRequestOptions } from '~src/fetch.ts'
import { localI18n } from '~src/i18n.ts'
import { getCookie } from '~src/utils.ts'
import { toastError, toastWarning } from '~src/globals/toastr.ts'

const pendingRequests: { [key: string]: AbortController } = {}
const removePendingRequest = (url: string | undefined, abort = false): void => {
  if (url && pendingRequests[url]) {
    if (abort) {
      pendingRequests[url].abort()
    }
    delete pendingRequests[url]
  }
}

export class ResponseError<T> extends Error {
  constructor(
    message: string,
    public readonly response: Response,
    public readonly body: T,
  ) {
    super(message)
    this.name = 'ResponseError'
  }
}

export async function request<RETURN_TYPE>(
  url: string,
  options?: JsonRequestOptions,
  httpOptions?: RequestInit,
): Promise<RETURN_TYPE> {
  const { t } = localI18n({
    'de-AT': {
      'Es ist ein Fehler aufgetreten.': 'Es ist ein Fehler aufgetreten.',
    },
    'de-DE': {
      'Es ist ein Fehler aufgetreten.': 'Es ist ein Fehler aufgetreten.',
    },
    'cs-CZ': {
      'Es ist ein Fehler aufgetreten.': 'Vyskytla se chyba.',
    },
    'fr-FR': {
      'Es ist ein Fehler aufgetreten.': 'Une erreur est survenue.',
    },
    'en-GB': {
      'Es ist ein Fehler aufgetreten.': 'An error has occurred.',
    },
    'es-ES': {
      'Es ist ein Fehler aufgetreten.': 'Se ha producido un error.',
    },
    'pl-PL': {
      'Es ist ein Fehler aufgetreten.': 'Wystąpił błąd.',
    },
  })

  const abortController = new AbortController()
  if (options?.abortGroup) {
    removePendingRequest(options?.abortGroup, true)
    pendingRequests[options?.abortGroup] = abortController
  }

  const csrfToken = getCookie('CSRF_TOKEN')

  if (!csrfToken) {
    throw Error('Csrf token not found')
  }

  if (options?.queryParams) {
    const queryParams = new URLSearchParams(options.queryParams).toString()
    url += `?${queryParams}`
  }

  const catchToast = options?.catchToast ?? 'generic'

  function showCatchToast(error: Error) {
    if (error.name === 'AbortError' || catchToast === false) {
      return // Do nothing on abort or if catchToast is explicitly false
    }

    if (error instanceof ResponseError && error.response.status === 422) {
      // On Laravel validation error, show validation toast
      toastError(error.body.message || error.message)
      return
    }

    if (!navigator.onLine) {
      toastWarning(t('Keine Internetverbindung.'))
      return
    }

    if (catchToast === 'message') {
      const message = error instanceof ResponseError && error.body.message ? error.body.message : error.message
      toastError(message)
      return
    }

    if (catchToast === 'generic') {
      toastError(t('Es ist ein Fehler aufgetreten.'))
      return
    }
  }

  const requestOptions: RequestInit = {
    method: options?.method ?? 'GET',
    credentials: 'same-origin', // default since 2018, but not for safari 11 :P
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': getCookie('CSRF_TOKEN')!,
      ...(options?.headers as Record<string, string>),
    },
    signal: abortController.signal,
    ...httpOptions,
    body: options?.body ? JSON.stringify(options.body) : null,
  }

  return fetch(url, requestOptions)
    .then(async (r) => {
      let res

      if (r.status === 204) {
        res = null
      } else if ((requestOptions.headers as Record<string, string>)['Content-Type'] === 'application/json') {
        res = await r.json()
      } else {
        res = await r.text()
      }

      if (r.status > 399) {
        throw new ResponseError(t('Es ist ein Fehler aufgetreten.'), r, res)
      }
      return res
    })
    .catch((error) => {
      showCatchToast(error)

      throw error
    })
}

// generated by script
export const i18nMessages = {
  'de-AT': {
    'Es ist ein Fehler aufgetreten.': 'Es ist ein Fehler aufgetreten.',
    'Keine Internetverbindung.': 'Keine Internetverbindung.',
  },
  'de-DE': {
    'Es ist ein Fehler aufgetreten.': 'Es ist ein Fehler aufgetreten.',
    'Keine Internetverbindung.': 'Keine Internetverbindung.',
  },
  'cs-CZ': {
    'Es ist ein Fehler aufgetreten.': 'Vyskytla se chyba.',
    'Keine Internetverbindung.': 'Žádné připojení k internetu.',
  },
  'fr-FR': {
    'Es ist ein Fehler aufgetreten.': 'Une erreur est survenue.',
    'Keine Internetverbindung.': 'Pas de connexion Internet.',
  },
  'en-GB': {
    'Es ist ein Fehler aufgetreten.': 'An error has occurred.',
    'Keine Internetverbindung.': 'No internet connection.',
  },
  'es-ES': {
    'Es ist ein Fehler aufgetreten.': 'Se ha producido un error.',
    'Keine Internetverbindung.': 'Sin conexión a Internet.',
  },
  'pl-PL': {
    'Es ist ein Fehler aufgetreten.': 'Wystąpił błąd.',
    'Keine Internetverbindung.': 'Brak połączenia z Internetem.',
  },
}
// !generated by script
